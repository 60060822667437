// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-form-js": () => import("./../../src/pages/contact/form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-forms-index-js": () => import("./../../src/pages/forms/index.js" /* webpackChunkName: "component---src-pages-forms-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-index-js": () => import("./../../src/pages/links/index.js" /* webpackChunkName: "component---src-pages-links-index-js" */)
}

